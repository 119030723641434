body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Open Sans Semibold", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: "Fira Code Retina", source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.list-group-horizontal .list-group-item+.list-group-item {
    width: max-content;
}

.title{
    margin-top: 10px;
}

.logo{
    width: 219px;
    float: right;
}


#disconnect {
    margin-left: 92%;
}
